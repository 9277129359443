.driveLayout {
  margin: 1%;
  padding: 1%;
  display: flex;
}

button {
  width: 100%;
  color: #000;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  background-color: #f9e64b;
  background-image: url("https://upload.wikimedia.org/wikipedia/commons/1/12/Google_Drive_icon_%282020%29.svg");
  background-position: 6px 10px;
  background-repeat: no-repeat;
  background-size: 28px;
  border: none;
  border-radius: 10px;
  margin: 10px;
  padding: 15px 15px 15px 40px;
  font-size: 16px;
  text-decoration: none;
  display: inline-block;
}

button#driveExport {
  background-position: 97% 10px;
  background-repeat: no-repeat;
  background-size: 28px;
  padding: 15px 40px 15px 15px;
}

button:hover {
  box-shadow: inset 0 0 0 1px #2222, inset 0 0 0 3px #2222;
}

.driveiFrameLayout {
  width: 70%;
  height: 550px;
  margin: auto;
}

#content {
  color: #f96e4b;
  display: block;
}

@media (min-width: 320px) and (max-width: 1024px) {
  .driveLayout {
    display: block;
  }

  .driveiFrameLayout {
    width: 100%;
    height: 300px;
  }
}

/*# sourceMappingURL=index.8e117332.css.map */
